import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import styled, { css, keyframes } from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import {
  TopSection,
  Section,
  TopTitleWrapper,
  Description,
  DescriptionWrapper,
  PageTopThumbnailWrapper,
  Thumbnail,
  colors,
  media,
  commonKeyframes,
  easings,
} from '~/styles';
import { useIntersection } from '~/hooks';
import {
  WorkArticle,
  Tag,
  RelatedWorksWithTag,
  RelatedWorks,
} from '~/types/work';
import RoundedButton from '~/components/RoundedButton';
import NoImgSvg from '~/assets/svgs/no-image.svg';

const WorkTemplate: React.VFC<
  PageProps<
    undefined,
    {
      work: WorkArticle;
      relatedWorksByTags: RelatedWorksWithTag[];
      relatedWorksByAll: RelatedWorks;
    },
    { tag: Tag | undefined }
  >
> = (props) => {
  const {
    pageContext: { work, relatedWorksByTags, relatedWorksByAll },
    location,
  } = props;
  const { t, i18n } = useTranslation();
  const [isTextIntersection, textIntersectionRef] = useIntersection();
  const [isRoleIntersection, roleIntersectionRef] =
    useIntersection<HTMLDivElement>();
  const [isCreditIntersection, creditIntersectionRef] =
    useIntersection<HTMLDivElement>();
  const [isLinkIntersection, linkIntersectionRef] =
    useIntersection<HTMLDivElement>();
  const [isRelatedIntersection, relatedIntersectionRef] =
    useIntersection<HTMLDivElement>();

  const currentTag = location.state?.tag ?? 'ALL';
  const localizedWork = i18n.language === 'en' && work.en ? work.en : work.ja;
  const relatedWorks = useMemo(() => {
    if (currentTag === 'ALL') {
      return relatedWorksByAll;
    }

    const result = relatedWorksByTags.filter(
      (works: any) => works.tag === currentTag,
    );
    return result.length > 0
      ? ({ prev: result[0].prev, next: result[0].next } as RelatedWorks)
      : null;
  }, [location.state]);

  return (
    <>
      <SEO title={localizedWork.title ?? ''} />
      <Layout>
        <TopSection>
          <PageTopThumbnailWrapper>
            <Thumbnail
              image={work.thumbnail as any}
              style={
                work.hotspot &&
                ({
                  '--image-position': `${100 * work.hotspot.y}%`,
                } as React.CSSProperties)
              }
              alt="thumbnail"
            />
          </PageTopThumbnailWrapper>
        </TopSection>
        <ImageCredit>{work.imageCredit}</ImageCredit>
        <Section ref={textIntersectionRef}>
          <StyledTopTitleWrapper>
            <StyledTitleWithCatchCopy
              catchCopy={localizedWork.title ?? ''}
              catchSub={localizedWork.client}
              isIntersection
            />
            <TagWrapper>
              {work.tags.map((tag) => (
                <TagLabel key={tag as string}>{tag}</TagLabel>
              ))}
            </TagWrapper>
          </StyledTopTitleWrapper>
          {localizedWork.text && (
            <DescriptionWrapper
              showAnim
              isIntersection={isTextIntersection}
              delay={0.3}
            >
              <Description>{localizedWork.text}</Description>
            </DescriptionWrapper>
          )}
        </Section>
        <StaffRoll>
          {localizedWork.role && (
            <StaffRollItem
              ref={roleIntersectionRef}
              isIntersection={isRoleIntersection}
            >
              <Title>{t('work.role')}</Title>
              <RolesText>{localizedWork.role}</RolesText>
            </StaffRollItem>
          )}
          {localizedWork.credits && localizedWork.credits.length > 0 && (
            <StaffRollItem
              ref={creditIntersectionRef}
              isIntersection={isCreditIntersection}
            >
              <Title>{t('work.credit')}</Title>
              <CreditList>
                {localizedWork.credits.map((item) => (
                  <CreditItem key={item?.name}>
                    <CreditText>{item?.position}</CreditText>
                    <CreditText>{item?.name}</CreditText>
                  </CreditItem>
                ))}
              </CreditList>
            </StaffRollItem>
          )}

          {localizedWork.link && (
            <StaffRollItem
              ref={linkIntersectionRef}
              isIntersection={isLinkIntersection}
            >
              <Title>{t('work.link')}</Title>
              <LinkText
                href={localizedWork.link}
                target="_blank"
                rel="noreferrer"
              >
                {localizedWork.link}
              </LinkText>
            </StaffRollItem>
          )}
        </StaffRoll>

        <Section>
          {relatedWorks && (
            <RelatedWorksWrapper
              ref={relatedIntersectionRef}
              isIntersection={isRelatedIntersection}
            >
              <RelatedWork>
                <RelatedWorkPosition>prev</RelatedWorkPosition>
                {relatedWorks.prev ? (
                  <RelatedWorkCard
                    to={`/works/${relatedWorks.prev.slug}`}
                    state={{ tag: currentTag }}
                  >
                    <RelatedWorkThumbnailWrapper>
                      <RelatedWorkThumbnail
                        image={relatedWorks.prev.thumbnail as any}
                        alt="previous works thumbnail"
                        style={
                          relatedWorks.prev.hotspot &&
                          ({
                            '--image-position': `${
                              100 * relatedWorks.prev.hotspot.y
                            }%`,
                          } as React.CSSProperties)
                        }
                      />
                    </RelatedWorkThumbnailWrapper>
                    <RelatedWorkTitle>
                      {i18n.language === 'en'
                        ? relatedWorks.prev.title.en
                        : relatedWorks.prev.title.ja}
                    </RelatedWorkTitle>
                  </RelatedWorkCard>
                ) : (
                  <NoImg />
                )}
              </RelatedWork>
              <RelatedWork>
                <RelatedWorkPosition>next</RelatedWorkPosition>
                {relatedWorks.next ? (
                  <RelatedWorkCard
                    to={`/works/${relatedWorks.next.slug}`}
                    state={{ tag: currentTag }}
                  >
                    <RelatedWorkThumbnailWrapper>
                      <RelatedWorkThumbnail
                        image={relatedWorks.next.thumbnail as any}
                        alt="next works thumbnail"
                        style={
                          relatedWorks.next.hotspot &&
                          ({
                            '--image-position': `${
                              100 * relatedWorks.next.hotspot.y
                            }%`,
                          } as React.CSSProperties)
                        }
                      />
                    </RelatedWorkThumbnailWrapper>
                    <RelatedWorkTitle>
                      {i18n.language === 'en'
                        ? relatedWorks.next.title.en
                        : relatedWorks.next.title.ja}
                    </RelatedWorkTitle>
                  </RelatedWorkCard>
                ) : (
                  <NoImg />
                )}
              </RelatedWork>
            </RelatedWorksWrapper>
          )}
          <RoundedButton
            text="Back to works"
            to="/works"
            state={{ tag: currentTag }}
          />
        </Section>
      </Layout>
    </>
  );
};

const ImageCredit = styled.div`
  text-align: right;
  font-size: 14px;

  ${media.mdUp} {
    padding-top: 16px;
    padding-right: 64px;
  }

  ${media.md} {
    text-align: center;
    padding-top: 16px;
  }

  ${media.smDown} {
    text-align: center;
    padding-top: 8px;
  }
`;

const TagWrapper = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
`;

const TagLabel = styled.li`
  padding: 6px 0;
  font-size: 14px;
  width: 80px;
  text-align: center;
  border-radius: 999px;
  border: 1px solid ${colors.white};

  opacity: 0;
  transform: translateY(4px);

  animation: ${commonKeyframes.fadeIn} 0.3s ${easings.easeOutCubic} 2.1s
      forwards,
    ${commonKeyframes.slideIn} 0.3s ${easings.easeOutCubic} 2.1s forwards;
`;

const StyledTopTitleWrapper = styled(TopTitleWrapper)`
  position: relative;
  grid-gap: 16px;
  align-content: center;
`;

const StyledTitleWithCatchCopy = styled(TitleWithCatchCopy)`
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.6);
`;

const StaffRoll = styled(Section)`
  display: grid;
  text-align: center;

  ${media.mdUp} {
    grid-gap: 120px;
  }

  ${media.mdDown} {
    grid-gap: 90px;
  }
`;

const StaffRollItem = styled.div<{ isIntersection?: boolean }>`
  display: grid;
  justify-content: center;
  grid-gap: 32px;
  opacity: 0;
  transform: translateY(32px);

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} 0.3s
          forwards,
        ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} 0.3s forwards;
    `}
`;

const Title = styled.h2`
  font-weight: normal;
  line-height: 1;
  margin: 0;
  padding: 0;

  ${media.mdUp} {
    font-size: 24px;
  }

  ${media.mdDown} {
    font-size: 20px;
  }
`;

const RolesText = styled.p`
  line-height: 2;

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const CreditList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.mdUp} {
    gap: 32px;
    justify-content: center;
  }

  ${media.mdDown} {
    gap: 20px;
    justify-content: space-around;
  }
`;

const CreditItem = styled.li`
  display: gird;
  grid-gap: 8px;
  justify-items: center;
`;

const CreditText = styled.p`
  line-height: 2;
  white-space: pre-wrap;

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const LinkText = styled.a`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: auto 0 0;
    height: 1px;
    width: 100%;
    background-color: ${colors.white};
  }

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const RelatedWork = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto 1fr;
  width: 100%;

  ${media.mdDown} {
    grid-gap: 16px;

    &:nth-child(1) {
      order: 2;
    }
  }
`;

const RelatedWorkPosition = styled.div`
  font-size: 24px;

  ${media.mdDown} {
    font-size: 20px;
  }
`;

const RelatedWorkCard = styled(Link)`
  display: grid;
  grid-gap: 24px;
  opacity: 0;
  transform: translateY(24px);

  ${media.mdDown} {
    grid-gap: 14px;
  }
`;

const NoImg = styled.img.attrs({ src: NoImgSvg, alt: 'no-image' })`
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: translateY(24px);
  object-position: right top;
  object-fit: contain;
`;

const RelatedWorksWrapper = styled.div<{ isIntersection?: boolean }>`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  ${media.mdDown} {
    grid-template-columns: 100%;
    grid-gap: 24px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      ${RelatedWork} {
        ${RelatedWorkCard}, ${NoImg} {
          animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic}
              forwards,
            ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} forwards;
        }
        &:nth-child(1) {
          ${RelatedWorkCard}, ${NoImg} {
            animation-delay: 0.3s;
          }
        }

        &:nth-child(2) {
          ${RelatedWorkCard}, ${NoImg} {
            animation-delay: 0.45s;
          }
        }
      }
    `}
`;

const RelatedWorkThumbnailWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
`;

const RelatedWorkThumbnail = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.6s ${easings.easeOutCubic};

  img {
    object-position: center var(--image-position) !important;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5) inset;
    opacity: 1;
    transition: opacity 0.6s ${easings.easeOutCubic};
  }

  &:hover {
    transition-duration: 0.4s;
    transform: scale(1.05);

    &::after {
      transition-duration: 0.3s;
      opacity: 0;
    }
  }
`;

const RelatedWorkTitle = styled.h3`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const noImgFadeIn = keyframes`
  to {
    opacity: 0.7;
  }
`;

export default WorkTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
